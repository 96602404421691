import React, { useState } from 'react'
import Style from './videoContent.module.scss'
import DocumentariesIcon from "../../../../assets/images/dashboardModule/settings/documentries.svg";
import ClientReviewIcon from "../../../../assets/images/dashboardModule/settings/clientReview.svg";
import ExplainerVideoIcon from "../../../../assets/images/dashboardModule/settings/explainerVideo.svg";
import TestimonalsIcon from "../../../../assets/images/dashboardModule/settings/testimonals.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/images/leftArrow.svg";
import VideoContentCard from '../videoContentCard/videoContentCard';
import { caseStudyTypeEnum } from '../../../../shared/js/enums';
import { getOnboardingVideosAPICall, getPurchaseProgramAPICall } from '../../../home/homeService/homeService';
import { Spinner } from '../../../../shared/components/spinner/spinner';
import { getYoutubeVideoId } from '../../../../shared/js/getYoutubeVideoId';
import { WelcomeModel } from '../../../home/model/welcomeModel';
import boostExplainer from "../../../../assets/images/boost-explainer.png";
import empowermentExplainer from "../../../../assets/images/empowerment-explainer.png";
import unstoppableExplainer from "../../../../assets/images/unstoppable-explainer.png";

const cardComponent = [
  { id: 1, icon: ExplainerVideoIcon, title: 'Explainer Video', type: 'explainerVideo' },
  { id: 2, icon: TestimonalsIcon, title: 'Testimonials', type: 'testimonials' },
  { id: 3, icon: DocumentariesIcon, title: 'Documentaries', type: 'documentaries' },
  { id: 4, icon: ClientReviewIcon, title: 'Client Reviews', type: 'clientReview' }
]
let _ExplainerVideo = [
  { id: 1, thumbnail: boostExplainer, title: "Boost Explainer", videoUrl: '' },
  { id: 2, thumbnail: empowermentExplainer, title: "Empowerment Explainer", videoUrl: '' },
  { id: 3, thumbnail: unstoppableExplainer, title: "Unstoppable Explainer", videoUrl: '' },
]
const defaultData = {
  slides: [
    {
      title: "TRAILER - BlueRain",
      videoUrl: "https://www.youtube.com/embed/KPaLm_C7iMQ?autoplay=1",
    },
    {
      title: "BlueRain - PTSD The Silent Suffering",
      videoUrl: "https://www.youtube.com/embed/Lh5PoKJ5pYU?autoplay=1",
    },
    {
      title: "Taboo of a Broken Brain Trailer",
      videoUrl: "https://www.youtube.com/embed/0BOLCKLM8Vo?autoplay=1",
    },
    {
      title: "Taboo of A Broken Brain",
      videoUrl: "https://www.youtube.com/embed/vTipXj6VrXQ?autoplay=1",
    },
  ],
  title: "Client Reviews",
  backgroundImage: "",
  title: "Client Reviews",
};

const VideoContent = () => {
  const [isShowContent, setIsShowContent] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowTitle, setIsShowTitle] = useState(true)
  const [selectedCard, setSelectedCard] = useState('')
  const [activeData, setActiveData] = useState(defaultData);
  const [isThumbnail, setIsThumbnail] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);
  const [isShowTestimonialButton, setIsShowTestimonialButton] = useState(false);


  const handleClickCard = (cardName, type) => {
    setIsShowContent(false)
    setSelectedCard(cardName)
    setCaseStudiesData(type)
    // setIsThumbnail(type==='explainerVideo')
    console.log("type", type);
    setIsShowButton(type === 'clientReview');
    setIsShowTestimonialButton(type === "testimonials");
  }
  const setCaseStudiesData = async (page) => {
    let _data = defaultData;
    let _IsSetData = false;
    switch (page) {
      case "clientReview":
        _data = await getOnboardingVideos(
          3,
          caseStudyTypeEnum.clientReview,
        );
        setIsShowTitle(true);
        setIsThumbnail(false);
        _IsSetData = true
        break;
      case "documentaries":
        _data = await getOnboardingVideos(
          2,
          caseStudyTypeEnum.documentry,
        );
        setIsShowTitle(true)
        setIsThumbnail(false);
        _IsSetData = true
        break;
      case "testimonials":
        _data = await getOnboardingVideos(
          2,
          caseStudyTypeEnum.testimonial,
        );
        setIsShowTitle(false);
        setIsThumbnail(false);
        _IsSetData = true
        break;
      case "explainerVideo":
        // await getExplainerVideo();
        // setIsShowTitle(false)
        // _IsSetData = false
        _data = await getOnboardingVideos(
          2,
          caseStudyTypeEnum.explainerVideo,
        );

        setIsShowTitle(false);
        setIsThumbnail(true);
        _IsSetData = true
        break;
      default:
        break;
    }
    if (_IsSetData) {
      setActiveData({ ..._data });
    }
  };
  const getOnboardingVideos = async (planId, caseStudyType, dataType) => {
    let _data = {};
    setIsLoading(true);
    let _response = await getOnboardingVideosAPICall('', caseStudyType);
    setIsLoading(false);
    if (_response.isSuccess) {
      _data.slides = _response.videoList;
    }
    return _data;
  };
  const getExplainerVideo = async () => {
    let slides = {}
    let _data = _ExplainerVideo.map((video) => {
      return getWelcomeData(video.id)
        .then(res => {
          video.videoUrl = res
          return video
        })
    })
    Promise.all(_data)
      .then(results => {
        slides = results
        setActiveData({ slides })
      })
      .catch(e => {
        console.error(e);
      })
  };
  const getWelcomeData = async (planId) => {
    let _response = await getPurchaseProgramAPICall();
    if (_response.isSuccess) {
      let _plan = _response.purchaseProgramList.find(
        (plan) => plan.id === planId
      );
      let _welcomeData = new WelcomeModel();
      const { authorName, authorDesignation, imageUrl, introVideo } = _plan;
      _welcomeData = {
        authorName: authorName,
        authorDesignation: authorDesignation,
        image: `${imageUrl}`,
        video: introVideo,
      };
      let _videoId = await setThumbnailVideo(_welcomeData.video);
      return _videoId;
    }
  };
  const setThumbnailVideo = async (videoUrl) => {
    let _id = await getYoutubeVideoId(videoUrl);
    return _id
  };

  const handleVideoPlay =(index)=>{
    let _activeData = activeData;
    console.log("index",index);
    let _slides = _activeData.slides.map((item,ind)=>{
      if(index === ind){
        item.isPlaying = true;
      }else{
        item.isPlaying = false;
      }
      return item;
    })
    _activeData.slides = _slides;


    setActiveData({..._activeData});

  }
  return (
    <div className={Style.mainContainer}>
      {isShowContent ?
        <div >
          <div className={`d-flex justify-content-center mb-3 `}>
            <h5>
              Video Content
            </h5>
          </div>
          <div className={Style.contentCardContainer}>
            {cardComponent.map((card) => {
              return (
                <div
                  className={Style.cardContainer}
                  onClick={() => handleClickCard(card.title, card.type)}
                >
                  <div><img src={card.icon} /></div>
                  <p>{card.title}</p>
                </div>
              )
            })}
          </div>
        </div> :
        <div>
          <div className={`row`}>
            <div className='col-1' onClick={() => setIsShowContent(true)} >
              <LeftArrow className={Style.arrowStyle} />
            </div>
            <div className='col-10 d-flex justify-content-center'>
              <h5>
                {selectedCard}
              </h5>
            </div>
          </div>
          {isLoading && (
            <div className="py-5">
              <Spinner isWhite={true} />
            </div>
          )}
          {!isLoading && !activeData.slides.length && (
            <div className="d-flex justify-content-center py-5">
              <p>Videos are not available</p>
            </div>
          )}
          {!isLoading && (<div
            className={`${Style.carouselContainer} ${activeData?.slides?.length === 1
                ? Style.singleCarouselContainer
                : ""
              } mt-3 pt-3 mb-5 pb-2`}
          >
            {activeData.slides?.map((slide,index) => (
              <VideoContentCard
                slide={slide}
                index={index}
                handleVideoControl={handleVideoPlay}
                thumbnail={slide.thumbnail}
                isShowTitle={isShowTitle}
                isThumbnail={isThumbnail}
              />
            ))}
            {isShowButton ? <div className="d-flex justify-content-center">
              <button className={`white-btn ${Style.clientReview}`}>
                <a
                  className="text-decoration-none text-dark"
                  href="https://blissiree.com/video-testimonials/"
                  target="_blank"
                >
                  More Client Reviews
                </a>
              </button>
            </div> : null}
            {
              isShowTestimonialButton &&
              <div className="d-flex flex-column ">
                <span className='mb-3 text-center'>To understand what to expect from your Emotional Empowerment & Unstoppable You Program.</span>
                <button className={`white-btn ${Style.clientReview}`}>
                  <a
                    className="text-decoration-none text-dark"
                    href="https://blissiree.com/video-testimonials/"
                    target="_blank"
                  >
                    View More
                  </a>
                </button>
              </div>

            }
          </div>)}
        </div>
      }
    </div>
  )
}

export default VideoContent