import Style from "./empowermentAndUnstoppablePopup.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { TasksEnum } from "../../js/tasksEnum";
import { useTimer } from "react-timer-hook";
import { useEffect, useState } from "react";

const EmpowermentAndUnstoppablePopup = ({
  currentSession,
  leftTime,
  handlePopup,
  isMaintenance,
  isEmopwerment,
}) => {
  const expiryTimestamp = new Date();
  let _seconds = 0;

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + leftTime);

  // const { seconds, minutes, hours, days, restart } = useTimer({
  //   expiryTimestamp,
  //   onExpire: () => {},
  // });

  useEffect(() => {
    setDays(leftTime > 1439 ? Math.floor(leftTime / 1440) : 0);
    setHours(leftTime > 59 ? Math.floor((leftTime / 60) % 24) : 0);
    setMinutes(leftTime < 60 ? leftTime : leftTime % 60);
  }, [leftTime]);
  const handleClick = () => {
    handlePopup?.();
  };

  return (
    <div className={`${Style.popup} mt-3 pb-5`}>
      {
        <div
          onClick={handleClick}
          className={`d-flex justify-content-end cursor-pointer ${Style.cross}`}
        >
          <CloseIcon />
        </div>
      }
      <div
        className={`d-flex flex-column align-items-center   ${Style.content}`}
      >
        {/* <h5 className="mb-5">
          After
          {days ? (
            ` ${days}   days `
          ) : (
            <>
              {hours ? ` ${hours}   hours ` : ""}
              {minutes ? ` ${hours ? "and" : ""} ${ Math.round(minutes * 10) /10 }  minutes ` : ""}
              {!days && !hours && !minutes && (
                <>{_seconds ? `  ${_seconds}   seconds ` : ""}</>
              )}
            </>
          )}
        </h5> */}
        <h5 className="mb-5">
          {currentSession === 0 && "After two hours"}
          {/* {!isEmopwerment && (
            <>{currentSession > 0 && !isMaintenance && "After 24 hours"}</>
          )} */}
          {isEmopwerment && (
            <>{currentSession > 0 && !isMaintenance && "After 3 days"}</>
          )}
          {isMaintenance && "After 1 month"}
        </h5>
        {/* after three days */}
        {isEmopwerment && (
          <>
            {currentSession > 0 && !isMaintenance && (
              <>
                <p className="text-justify">
                  Congratulations on your journey towards happiness and a more
                  inspired life.
                </p>
                <p className="text-justify">
                  To get the most out of your Emotional Empowerment Program,
                  listen to each session in a darkened room. The best place is
                  your bedroom where you feel safe and can relax without any
                  distractions while going to sleep. This gives your brain 6 – 8
                  hours to integrate the changes into your mind and your brain.
                  Don’t forget to listen until the music ends.
                </p>
                <p className="text-justify">
                  If you are at high risk and suicidal, contact Lifeline in your
                  capital city.
                </p>
              </>
            )}
          </>
        )}

        {/* after two hours */}
        {currentSession === 0 && (
          <>
            <p className="text-justify">
              The pre-session audio relaxes your nervous system. It helps
              prepare your mind and brain for your session so your brain can
              make the necessary changes so reach a state of calmness and inner
              peace. Listen to it without distractions at least 2 hours before
              your next emotional empowerment session for maximum benefit. Keep
              your eyes closed until the end of the music.
            </p>
          </>
        )}
        {isMaintenance && (
          <p className="text-justify">
            It is a pleasure helping you work towards emotional freedom and
            happiness. The program delves deeply into your emotional memory and
            behavioural patterns. If your suffering is severe or you have
            suicidal thoughts, complete a session every three days. It will help
            you feel more stable and able to cope.
          </p>
        )}
      </div>
    </div>
  );
};
export default EmpowermentAndUnstoppablePopup;
