import React, { useEffect, useState } from 'react'
import Style from './reward.module.scss'
import Blissiree from "../../../assets/images/mono-logo.svg";
import { ReactComponent as WinnerBadge } from "../../../assets/images/winnerBadge.svg";
import AwardImageWeb from "../../../assets/images/reward.png";
import AwardImageIpad from "../../../assets/images/AwardScreenIpad.png";
import AwardImageMobile from "../../../assets/images/AwardScreenMobile.png";
import CustomButton from '../customButton/customButton';
import { getUserActiveStatus } from '../../js/getUserActiveStatus';
import { useLocation, useNavigate } from "react-router-dom";
import { webFlowRoutes } from '../../js/webFlowRoutes';
import { UserStatus } from '../../js/enums';
const Reward = () => {
    const [isMobileTab, setIsMobileTab] = useState(false);
    const [isIpadTab, setIsIpadTab] = useState(false);
    const navigate = useNavigate();
    const search = useLocation().search;
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return window.removeEventListener("resize", handleResize());
      }, []);
      const handleResize = () => {
        setIsMobileTab(window.innerWidth <= 450);
        setIsIpadTab(window.innerWidth <= 850 && window.innerWidth >= 451);
      };
      const handleButtonClick = () =>{
        let _status = getUserActiveStatus();
        let _redirectUrl = webFlowRoutes.find(
          (routeObj) => routeObj.status === _status
        )?.route;

        if (_redirectUrl === "/") {
          _redirectUrl = "/email-verification";
        }
        if (_status === UserStatus.active) {
          const _paramsRedirectUrl = new URLSearchParams(search).get(
            "redirectUrl"
          );
          if (_paramsRedirectUrl) {
            _redirectUrl = _paramsRedirectUrl;
          }
        }
        navigate(_redirectUrl);
      }
  return (
    <div>
      {isMobileTab?
        <MobileView
            buttonClick={handleButtonClick}
        />:
        (
            isIpadTab?
            <IpadView
            buttonClick={handleButtonClick}
            />
             :
            <div className={`container-fluid ${Style.mainContainer} `}>
                <div className='row w-100'>
                    <LeftPortion/>
                    <RightPortion
                        buttonClick={handleButtonClick}
                    />
                </div>
            </div>
        )}
    </div>
  )
}

export default Reward

const LeftPortion = () =>{
    return(
        <div className={`${Style.leftPortionContainer}  col-5  order-1`}>
            <div><img src={Blissiree} alt="logo" height={200} width={280}/></div>
            <div className={Style.content1}>
                <p className={Style.badgeSection}>
                    <span className={Style.title}>WINNER</span>
                    <WinnerBadge className={Style.badge}/>
                </p>
                <p>MOST INNOVATION</p>
                <p>Natural Mental Health Service</p>
                <p>2022-WA</p>
            </div>
            <div className={Style.content1}>
                <span>...in the Healthcare &amp;</span>
                <span>Pharmaceutical Awards 2022,</span>
                <span>hosted by Global Health &amp; Pharma</span>
            </div>
        </div>
    )
}
const RightPortion = ({buttonClick}) =>{
    return(
        <div className={`${Style.rightPortionContainer}  col-7 order-2`}>
            <div className={Style.rightPortionImage}><img className={Style.webImage} src={AwardImageWeb} alt="award image"/>
            <div className={Style.content}>
                <div className={Style.buttonContainer}>
                    <CustomButton
                        title={'Next'}
                        buttonStyle={Style.buttonStyle}
                        handleButtonClick={buttonClick}
                    />
                </div>
            </div></div>
        </div>
    )
}
const IpadView = ({buttonClick}) =>{
    return(
            <div className={Style.ipadView}>
                <img className={Style.ipadImage} src={AwardImageIpad} alt="award image"/>
                <div className={Style.content}>
                    <div className={Style.content1}>
                        <p className={Style.badgeSection}>
                            <span className={Style.title}>WINNER</span>
                            <WinnerBadge/>
                        </p>
                        <p>MOST INNOVATION</p>
                        <p>Natural Mental Health Service</p>
                        <p>2022-WA</p>
                    </div>
                    <div className={Style.content1}>
                        <span>...in the Healthcare &amp;</span>
                        <span>Pharmaceutical Awards 2022,</span>
                        <span>hosted by Global Health &amp; Pharma</span>
                    </div>
                    <div className={Style.buttonContainer}>
                        <CustomButton
                            title={'Next'}
                            buttonStyle={Style.buttonStyle}
                            handleButtonClick={buttonClick}
                        />
                    </div>
                </div>
            </div>
    )
}
const MobileView = ({buttonClick}) =>{
    return(
            <div className={Style.mobileView}>
                <img className={Style.ipadImage} src={AwardImageMobile} alt="award image"/>
                <div className={Style.content}>
                    <div className={Style.content1}>
                        <p className={Style.badgeSection}>
                            <span className={Style.title}>WINNER</span>
                            <WinnerBadge className={Style.badge}/>
                        </p>
                        <p>MOST INNOVATION</p>
                        <p>Natural Mental Health Service</p>
                        <p>2022-WA</p>
                    </div>
                    <div className={Style.content1}>
                        <span>...in the Healthcare &amp;</span>
                        <span>Pharmaceutical Awards 2022,</span>
                        <span>hosted by Global Health &amp; Pharma</span>
                    </div>
                    <div className={Style.buttonContainer}>
                        <CustomButton
                            title={'Next'}
                            buttonStyle={Style.buttonStyle}
                            handleButtonClick={buttonClick}
                        />
                    </div>
                </div>
            </div>
    )
}