import React from "react";
import Style from "./audioPlayerCard.module.scss";
import { ReactComponent as LockIcon } from "../../../../assets/images/lock.svg";
import { ReactComponent as PlayButton } from "../../../../assets/images/audioPlayer/play.svg";
import wave from "../../../../assets/images/audioPlayer/Equalizer Gif.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  pauseMusic,
  setPlaylist,
  setPlayerVisibility,
} from "../../../../redux/Action/wavesurfer";
import environment from "../../../../environment";
import DisplayImage from "../../../../shared/components/displayImage/displayImage";

const AudioPlayerCard = ({
  musicList,
  title,
  image,
  veritcal = true,
  isLock = false,
  index,
  isPlay,
  myPlayerIndex,
  isPlaylist,
  handlePlaylist,
}) => {
  const playerVisibility = useSelector((state) => state.playerVisibility);

  const loadAPlaylist = (playlist, track = 0) => {
    dispatch(setPlaylist({
      playlist,
      trackIndex: track
    }));
  }

  const dispatch = useDispatch();
  const onPlay = () => {
    loadAPlaylist(musicList.map((track) => { return {
      id: track.id,
      url: track.music,
      title: track.title,
      image: track.image
    }; }), index);

    if (!playerVisibility) {
      dispatch(setPlayerVisibility(true))
    }
  };

  const handlePlaylistClick = () => {
    isPlaylist && handlePlaylist();
  };

  return (
    <div
      onClick={handlePlaylistClick}
      className={`${Style.container} text-truncate ${isPlaylist ? "cursor-pointer" : ""}`}
    >
      <div
        className={`d-flex justify-content-center align-items-center ${Style.imageContainer}`}
      >
        <div
          className={`rounded-circle ${Style.firstCircle} 
                    ${
                      veritcal
                        ? Style.verticalFirstcircle
                        : Style.horizontalFirstCircle
                    } `}
        >
          <div className={`rounded-circle ${Style.secondCircle}`}>
            <div className={`rounded-circle ${Style.thirdCircle}`}>
              <DisplayImage
                imageUrl={environment.serverUrl + image}
                className={`${Style.image}`}
                playlistImage={true}
              />
              { !isPlaylist && (
                <div className={`${Style.play}`}>
                  { isPlay ? <img src={wave} width="30px" /> : <PlayButton onClick={onPlay} /> }
                </div>
              )}
            </div>
          </div>
        </div>
        {isLock && (
          <div className={`${Style.lockIcon}`}>
            <LockIcon height={30} width={30} />
          </div>
        )}
      </div>
      <span>{title}</span>
    </div>
  );
};
export default AudioPlayerCard;
