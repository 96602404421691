export const formatedDate = (date) =>{
    let _day, _month, _year;
    let dataValue = new Date(date);
    _day =
      dataValue.getDate() > 9
        ? dataValue.getDate()
        : `0${dataValue.getDate()}`;
    _month =
      dataValue.getMonth() + 1 > 9
        ? dataValue.getMonth() + 1
        : `0${dataValue.getMonth() + 1}`;
    _year = dataValue.getFullYear();

    let _hours, _mints, _seconds;
    var d = new Date(date);
    _hours = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
    _mints = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
    _seconds = d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`;

    let _formatedDate = `${`${_year}-${_month}-${_day} ${_hours}:${_mints}:${_seconds}`}`;
    return _formatedDate;
}