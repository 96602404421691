import React, { useState } from 'react'
import Style from './promoCode.module.scss';
import InputField from "../../../../shared/components/inputField/inputField";
import CustomButton from "../../../../shared/components/customButton/customButton";
import { setPromoCodeSettingAPICall } from '../settingService/settingService';
import { handleToastMessage } from '../../../../shared/js/handleToastMessage';
const PromoCode = () => {
    const [promoCode, setPromoCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const handleChange = (value) => {
        setPromoCode(value)
    }
    const handleSubmit = async () => {
        if(promoCode){
            setIsLoading(true)
            let _response = await setPromoCodeSettingAPICall(promoCode)
            setIsLoading(false)
            if(_response){
                handleToastMessage('success',"Promo code apply successfully")
            }
        }
    }
  return (
    <>
        <div className={`d-flex justify-content-center mb-3 `}>
            <h5>
                Promo Code
            </h5>
        </div>
        <div className={`${Style.promoCodeContainer} py-5`}>
            <span>Promo Code</span>
            <InputField
                // label={"Promo Code"}
                placeholder={"00000000000000000000"}
                inputStyle={Style.inputStyle}
                onChange={handleChange}
            />
        </div>
        <div className="d-flex justify-content-center mt-5">
                <CustomButton
                  type="submit"
                  disabled={!promoCode}
                  handleButtonClick={handleSubmit}
                  isLoading={isLoading}
                  buttonStyle={Style.buttonStyle}
                >
                  {"Confirm"}
                </CustomButton>
              </div>
    </>
  )
}

export default PromoCode