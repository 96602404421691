import CustomButton from "../../../../shared/components/customButton/customButton";
import Style from "./premiumCard.module.scss";
const PremiumCard = ({ premium, handleChoosePackage, couponId }) => {
  const handleClick = () => {
    handleChoosePackage?.(premium.stripePriceId, premium.id,premium.price, premium.discountPrice);
  };

  return (
    <div
      className={`${Style.card} p-4 d-flex flex-column justify-content-between`}
    >
      <div>
        <h3 className="mb-0">{premium.title}</h3>
        <span className={`${Style.description}`}>{premium.titleTagline}</span>
          <div className="d-flex justify-content-end">
            {(premium.price !== premium.discountPrice) && premium.price ? (
              <h5 className={`${Style.underlinPriceContainer} `}>
                <del className="text-white">
                  <span className={`${Style.currency}`}>$</span>
                  {premium.price}
                </del>
              </h5>
            ) : (
              <div className="mt-3"></div>
            )}
          </div>
        <div className={`${Style.priceMainContainer}`}>
          <div className="d-flex justify-content-between">
            <h5 className={`${Style.priceContainer} `}>
              <span className={`${Style.currency} me-2`}>
                {premium.price > 0 ? "$" : ""}
              </span>
              {premium.price > 0 ? (
                <>
                  {(premium.price !== premium.discountPrice) ? (
                    premium.discountPrice
                  ) : (
                    <>
                      {premium.price}
                      <span style={{ fontSize: "1rem" }}>AUD</span>
                    </>
                  )}{" "}
                </>
              ) : (
                <>Free</>
              )}

              <span className={`${Style.duration} ms-2`}>
                /<span className={`ms-1`}>{premium.interval}</span>
              </span>
            </h5>
          </div>
          <span
            className={`d-flex justify-content-center text-center mb-1 ${Style.allowUser}`}
          >
            {/* {premium.allowUser > 0 && ` For ${premium.allowUser} Users`} */}
            {premium.shorDescription}
          </span>
          <div className="d-flex justify-content-center">
            {premium.shorDescription && (premium.price !== premium.discountPrice) ? (
              <p style={{ fontSize: 10 }}>
                {/* (Promo Code Applied) After 50% Discount */}
                  After 50% Discount
              </p>
            ) : (
              <p className="mb-5"></p>
            )}
          </div>
        </div>
        <div className={Style.listDescription}>
          <div dangerouslySetInnerHTML={{ __html: premium.description }}></div>
        </div>
      </div>
      <div className={`d-flex mt-3 justify-content-center`}>
        <CustomButton
          title={premium.isSubscribed ? "Subscribed" : "Choose"}
          handleButtonClick={handleClick}
          buttonStyle={`white-btn ${Style.whiteButton}`}
          disabled={premium.isSubscribed ? true : false}
        />
        {/* <button
          onClick={handleClick}
          className={}
        >
          {}
        </button> */}
      </div>
    </div>
  );
};

export default PremiumCard;
