import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import IntroductoryScreen from '../../../shared/components/introductoryScreen/introductoryScreen';
import boostBackground from "../../../assets/images/dashboardModule/empowerment/bg.png";
import empowermentBackground from "../../../assets/images/dashboardModule/empowerment/welcomeBg.png";
import unstoppableBackground from "../../../assets/images/dashboardModule/unstoppable/unstoppable.png";
import clock from "../../../assets/images/dashboardModule/empowerment/clock.svg";
import moon from "../../../assets/images/dashboardModule/empowerment/moon.svg";
import reset from "../../../assets/images/dashboardModule/empowerment/reset.svg";
import silent from "../../../assets/images/dashboardModule/empowerment/silent.svg";
import videoPlay from "../../../assets/images/dashboardModule/empowerment/videoPlay.svg";
import bulbe from "../../../assets/images/dashboardModule/empowerment/bulbe.svg";
import empowermentTestimonial from "../../../assets/images//homeModule/shareFeeling/bg.png";
import { caseStudyTypeEnum } from '../../../shared/js/enums';
import { getOnboardingVideosAPICall } from '../homeService/homeService';

const testimonialsEmpowerment = {
    slides: [
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/sIEWOwwXpvc?autoplay=1",
      },
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/PDtsgIdgass?autoplay=1",
      },
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/nPpS3nHdslk?autoplay=1",
      },
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/ssWRy43idG8?autoplay=1",
      },
    ],
    skipUrl: "/dashboard/empowerment",
    state: {
      stepper: 1,
    },
    backgroundImage: empowermentTestimonial,
    title: "Testimonials",
  };
  const testimonialsUnstoppable = {
    slides: [
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/sIEWOwwXpvc?autoplay=1",
      },
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/PDtsgIdgass?autoplay=1",
      },
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/nPpS3nHdslk?autoplay=1",
      },
      {
        title: "",
        videoUrl: "https://www.youtube.com/embed/ssWRy43idG8?autoplay=1",
      },
    ],
    skipUrl: "/dashboard/unstoppable",
    state: {
      stepper: 1,
    },
    backgroundImage: unstoppableBackground,
    title: "Testimonials",
  };
  const empowermentUnstoppablePre = [{
            step:1,
            title:'Making the most of your Free Trial',
            description:'Set time aside to complete your free trial. Try and relax before your trial so your brain is more receptive to the new changes.',
            Icon:reset,
            buttonText: 'Next'
  }]
  const testimonialsEmpowermentPost = [{
            step:1,
            title:'Making the most of Emotional Empowerment',
            description:'Congratulations on choosing my Emotional Empowerment Program. To get the most out of your program, we recommend you complete your pre-session audio at least 2 hours before your brain retraining session. <br/> <br/> We recommend doing this in the morning and listen to your session while you sleep at night. Please ensure you keep your eyes closed during your sessions until the music stops.',
            Icon:clock,
            buttonText: 'Next'
  }]
  const testimonialsUnstoppablePost = [{
            step:1,
            title:'Making the most of Unstoppable You',
            description:'Congratulations on choosing my life changing Unstoppable You Program. To get the most out of your program, we recommend you complete your pre-session audio at least 2 hours before your brain retraining session. <br/> <br/> We recommend doing this in the morning and listening to your session while you sleep at night. Please ensure you keep your eyes closed during your sessions until the music stops.',
            Icon:clock,
            buttonText: 'Next'
  }]
const EmpowermentAndUnstoppableWelcome = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { page } = state || {};
    const { sessionId } = state || {};
    const [activeData, setActiveData] = useState({});
    const [welcomeStep, setWelcomeStep] = useState([])
    const [onboardingContent ,setOnboardingContent] = useState([]);
    const [background, setBackground] = useState(boostBackground)

    useEffect(()=>{
        if(page === 'empowerment' || page === 'unstoppable'){
            setWelcomeStep(empowermentUnstoppablePre)
            setBackground(empowermentBackground)
          }else if(page === 'testimonialsEmpowerment' ){
            setWelcomeStep(testimonialsEmpowermentPost)
          }else if(page === 'testimonialsUnstoppable'){
            setWelcomeStep(testimonialsUnstoppablePost)
            setBackground(unstoppableBackground)
        }
        getOnboardingVideos();
    },[])

    const getOnboardingVideos =async ()=>{
      let _response = await getOnboardingVideosAPICall('', caseStudyTypeEnum.explainerVideo);
      if (_response.isSuccess) {
        setOnboardingContent(_response.videoList);
      }
    }

    useEffect(()=>{
        setCaseStudiesData(page)
    },[])

    const handleClick = async(step) => {
        if(page === 'empowerment' || page === 'unstoppable'){
            preSteps(step)
        }else if(page === 'testimonialsEmpowerment' ){
            preStepsSessionOneEmotional(step)
        }else if(page === 'testimonialsUnstoppable'){
            preStepsSessionOneUnstoppable(step)
        }
    }
    const preSteps = (step) =>{
        let _welcomeStep = {}
        switch(step){
            case 1:
                _welcomeStep = {
                    step:2,
                    title:'',
                    description:'Listen to the audios while you sleep at night. This ensures you get maximum benefit.<p class="pt-4" />Play on a low whisper volume, so you barely hear the speaking. The audios are designed to be below threshold listening, so it does not disturb you or your partner.',
                    Icon:moon,
                    buttonText: 'Next'
                }
                break;
            case 2:
                _welcomeStep = {
                    step:3,
                    title:'',
                    description:'Make sure there are no distractions while you listen to your life-changing brain retraining programs.<p class="pt-4" /> Ensure your room is darkened and your eyes are closed until the music stops.',
                    Icon:silent,
                    buttonText: 'Next'
                }
                break;
            case 3:
                _welcomeStep = {
                    step:4,
                    title:'',
                    description:'Play your pre-session audio at least 2 hours before listening to your free trial.<p class="pt-4" /> Remember, you have access to free unlimited support, so please reach out if you are unsure of anything to do with using your app.',
                    Icon:clock,
                    buttonText: "Let's start"
                }
                break;
            case 4:
                if(page === 'empowerment'){
                    navigate("/dashboard/empowerment", {
                        state: {
                            page: 'empowerment',
                            sessionId: sessionId
                        },
                      });
                }else if(page === 'unstoppable'){
                    navigate("/dashboard/unstoppable", {
                        state: {
                            page: 'unstoppable',
                            sessionId: sessionId
                        },
                      });
                }
                break;
        }
    setWelcomeStep([_welcomeStep])
    }
    const preStepsSessionOneEmotional = (step) =>{
        let _welcomeStep = {}
        
        switch(step){
            case 1:
                _welcomeStep = {
                    step:2,
                    title:'',
                    description:'You will have lessons, tips and activities to do prior to each brain retaining session. We recommend that you do these while you are still alert so you absorb your new-found knowledge. You need to complete each task before you can move forward to the next task.',
                    Icon:bulbe,
                    buttonText: 'Next'
                }
                break;
            case 2:
                _welcomeStep = {
                    step:3,
                    title:'',
                    description:'To better understand your Emotional Empowerment Program, I recommend you watch my 2-minute explanation video before starting the program. This will ensure you get the maximum benefit from your program.<p class="pt-4" /> Remember you have free access to unlimited chat support if you need assistance with your program or the app.',
                    Icon:videoPlay,
                    isVideoPlay:true,
                    videoId: onboardingContent[1].videoUrl,
                    thumbnail:onboardingContent[1].thumbnail,
                    buttonText: "Let's Start"
                }
                break;
            case 3:
                handleSkip()
                break;
        }
    setWelcomeStep([_welcomeStep])
    }
    const preStepsSessionOneUnstoppable = (step) =>{
        let _welcomeStep = {}
        
        switch(step){
            case 1:
                _welcomeStep = {
                    step:2,
                    title:'',
                    description:'You will have lessons, tips and activities to do before each brain retaining session. We recommend you do these while you are still alert so you absorb your new- found knowledge. You need to complete each task before you can move forward to the next task.',
                    Icon:bulbe,
                    buttonText: 'Next'
                }
                break;
            case 2:
                _welcomeStep = {
                    step:3,
                    title:'',
                    description:'To better understand your Unstoppable You Program, I recommend you watch my 2- minute explanation video before starting the program. This will ensure you get maximum benefit from your program. <p class="pt-4" /> Remember, you have free access to unlimited chat support if you need assistance with your program or the app.',
                    Icon:videoPlay,
                    isVideoPlay:true,
                    videoId: onboardingContent[2].videoUrl,
                    thumbnail:onboardingContent[2].thumbnail,
                    buttonText: "Let's Start"
                }
                break;
            case 3:
                handleSkip()
                break;
        }
    setWelcomeStep([_welcomeStep])
    }
    const setCaseStudiesData = async (page) => {
        let _data = {};
        switch (page) {

          case "testimonialsEmpowerment":
            _data = testimonialsEmpowerment
            break;
          case "testimonialsUnstoppable":
            _data = testimonialsUnstoppable;
            break;
          default:
            break;
        }
    
        setActiveData({ ..._data });
      };
    const handleSkip = () => {
        navigate(activeData.skipUrl, {
          state: activeData?.state || {},
        });
      };
  return (
    <div>
    {
        welcomeStep.map((data)=>
            <IntroductoryScreen
                step={data.step}
                title={data.title}
                Icon={data.Icon}
                description={data.description}
                videoId={data?.videoId}
                thumbnail={data?.thumbnail}
                isVideo={data?.isVideoPlay || false}
                backgroundImage={background}
                buttonText={data.buttonText}
                handleClick={handleClick}
            />
        )
    }
    </div>
  )
}

export default EmpowermentAndUnstoppableWelcome