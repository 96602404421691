import Style from "../wavesurfer.module.scss";
import heart from "../../../../assets/images/audioPlayer/heart.svg";
import environment from "../../../../environment.js";
import DisplayImage from "../../displayImage/displayImage.js";

const LeftPortion = ({ track, handlePopup }) => {
  const handleClick = () => {
    handlePopup(true);
  };

  return (
    <div className={`d-flex align-items-center ${Style.leftPortion}`}>
      <div className={`${Style.imageBox}`}>
        <DisplayImage imageUrl={ environment.serverUrl + track?.image } />
      </div>
      <div className={`d-flex flex-column ms-2`}>
        <span className={Style.title}>{track?.title}</span>
        <img
          className={`${Style.imgHeart} cursor-pointer`}
          onClick={handleClick}
          src={heart}
        />
      </div>
    </div>
  );
};

export default LeftPortion;