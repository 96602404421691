import Style from "./shareFeeling.module.scss";
import EmojiBox from "./emojiBox/emojiBox";
import { useEffect, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/images/rightArrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import empowermentBg from "../../../assets/images/homeModule/shareFeeling/bg.png";
import unstoppableBg from "../../../assets/images/homeModule/shareFeeling/bg1.png";
import bgImage from "../../../assets/images/homeModule/shareFeeling/background.png";

// -------- pre Emojis -----------
import Aggressive from "../../../assets/images/homeModule/shareFeeling/preEmojis/aggressive.png";
import Agitated from "../../../assets/images/homeModule/shareFeeling/preEmojis/agitated.png";
import Angry from "../../../assets/images/homeModule/shareFeeling/preEmojis/angry.png";
import Badmood from "../../../assets/images/homeModule/shareFeeling/preEmojis/badMood.png";
import PreNeutral from "../../../assets/images/homeModule/shareFeeling/preEmojis/neutral.png";
import PreJoyful from "../../../assets/images/homeModule/shareFeeling/preEmojis/joyful.png";
import Confused from "../../../assets/images/homeModule/shareFeeling/preEmojis/confused.png";
import Content from "../../../assets/images/homeModule/shareFeeling/preEmojis/content.png";
import Crying from "../../../assets/images/homeModule/shareFeeling/preEmojis/crying.png";
import Disappointed from "../../../assets/images/homeModule/shareFeeling/preEmojis/disappointed.png";
import Emotional from "../../../assets/images/homeModule/shareFeeling/preEmojis/emotional.png";
import Excited from "../../../assets/images/homeModule/shareFeeling/preEmojis/excited.png";
import Flustered from "../../../assets/images/homeModule/shareFeeling/preEmojis/flustered.png";
import Freefromproblem from "../../../assets/images/homeModule/shareFeeling/preEmojis/freeFromProblem.png";
import Grief from "../../../assets/images/homeModule/shareFeeling/preEmojis/grief.png";
import Happy from "../../../assets/images/homeModule/shareFeeling/preEmojis/happy.png";
import Happy2 from "../../../assets/images/homeModule/shareFeeling/preEmojis/happy2.png";
import Heavy from "../../../assets/images/homeModule/shareFeeling/preEmojis/heavy.png";
import Lighter from "../../../assets/images/homeModule/shareFeeling/preEmojis/lighter.png";
import Negative from "../../../assets/images/homeModule/shareFeeling/preEmojis/negative.png";
import Negativemood from "../../../assets/images/homeModule/shareFeeling/preEmojis/negativeMood.png";
import Nothanks from "../../../assets/images/homeModule/shareFeeling/preEmojis/noThanks.png";
import Overwhelm from "../../../assets/images/homeModule/shareFeeling/preEmojis/overWhelm.png";
import Sad from "../../../assets/images/homeModule/shareFeeling/preEmojis/sad.png";
import Scattered from "../../../assets/images/homeModule/shareFeeling/preEmojis/scattered.png";
import Uptight from "../../../assets/images/homeModule/shareFeeling/preEmojis/uptight.png";
import PreStressed from "../../../assets/images/homeModule/shareFeeling/preEmojis/stresssed.png";
import PreRelaxed from "../../../assets/images/homeModule/shareFeeling/preEmojis/relaxed.png";
import PreFree from "../../../assets/images/homeModule/shareFeeling/preEmojis/free.png";
import PreTense from "../../../assets/images/homeModule/shareFeeling/preEmojis/tense.png";
import PreSleepy from "../../../assets/images/homeModule/shareFeeling/preEmojis/sleepy.png";
import PrePeaceful from "../../../assets/images/homeModule/shareFeeling/preEmojis/peaceful.png";
// -------- post Emojis -----------
import AggressivePost from "../../../assets/images/homeModule/shareFeeling/postEmojis/aggressive.png";
import Blushing from "../../../assets/images/homeModule/shareFeeling/postEmojis/blushing.png";
import Light from "../../../assets/images/homeModule/shareFeeling/postEmojis/light.png";
import AngryPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/angry.png";
import Clam from "../../../assets/images/homeModule/shareFeeling/postEmojis/clam.png";
import ConfusedPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/confused.png";
import EmotionalPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/emotional.png";
import ExcitedPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/excited.png";
import Free from "../../../assets/images/homeModule/shareFeeling/postEmojis/free.png";
import HappyPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/happy.png";
import CryingPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/crying.png";
import HappyPost2 from "../../../assets/images/homeModule/shareFeeling/postEmojis/happy2.png";
import Jayful from "../../../assets/images/homeModule/shareFeeling/postEmojis/jayful.png";
import NegativePost from "../../../assets/images/homeModule/shareFeeling/postEmojis/negative.png";
import Neutral from "../../../assets/images/homeModule/shareFeeling/postEmojis/neutral.png";
import Peaceful from "../../../assets/images/homeModule/shareFeeling/postEmojis/peaceful.png";
import Peaceful2 from "../../../assets/images/homeModule/shareFeeling/postEmojis/peaceful2.png";
import Stressed from "../../../assets/images/homeModule/shareFeeling/postEmojis/stressed.png";
import Pensive from "../../../assets/images/homeModule/shareFeeling/postEmojis/pensive.png";
import Positive from "../../../assets/images/homeModule/shareFeeling/postEmojis/positive.png";
import Negative1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/negative1.png";
import Relaxed from "../../../assets/images/homeModule/shareFeeling/postEmojis/relaxed.png";
import SadPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/sad.png";
import SadPost2 from "../../../assets/images/homeModule/shareFeeling/postEmojis/sad2.png";
import Tense from "../../../assets/images/homeModule/shareFeeling/postEmojis/tense.png";
import Worried from "../../../assets/images/homeModule/shareFeeling/postEmojis/worried.png";
import HeavyPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/heavy.png";
import BadMoodPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/badMood.png";
import NegativeMoodPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/negativeMood.png";
import OverwhelmPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/overwhelm.png";
import GriefPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/greif.png";
import ContentPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/content.png";
import NotThinkingPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/notThinking.png";
import AgitatedPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/agitated.png";
import DispointedPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/dispointed.png";
import ScatteredPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/scattered.png";
import FlatFeeling from "../../../assets/images/homeModule/shareFeeling/postEmojis/flatFeeling.png";
import Smiling from "../../../assets/images/homeModule/shareFeeling/postEmojis/smiling.png";
import ConfusedPost1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/confused1.png";
import joyPost from "../../../assets/images/homeModule/shareFeeling/postEmojis/joy.png";
import emotional1Post from "../../../assets/images/homeModule/shareFeeling/postEmojis/emotional1.png";
import Angry1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/angry1.png";
import Scared from "../../../assets/images/homeModule/shareFeeling/postEmojis/scared.png";
import Excited1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/excited1.png";
import Crying1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/crying1.png";
import aggesive1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/aggesive1.png";
import Unhappy3 from "../../../assets/images/homeModule/shareFeeling/postEmojis/unhappy3.png";
import Annoyed1 from "../../../assets/images/homeModule/shareFeeling/postEmojis/annoyed1.png";
import Peaceful3 from "../../../assets/images/homeModule/shareFeeling/postEmojis/peaceful3.png";

import Popup from "../../../shared/components/popup/popup";
import { handleToastMessage } from "../../../shared/js/handleToastMessage";

const emojis = [
  { id: 1, title: "Negative mood", Icon: Aggressive, isSelected: false },
  { id: 2, title: "Emotional", Icon: Agitated, isSelected: false },
  { id: 3, title: "Angry", Icon: Angry, isSelected: false },
  { id: 4, title: "Negative", Icon: Badmood, isSelected: false },
  { id: 5, title: "Sad", Icon: Sad, isSelected: false },
  { id: 6, title: "Happy", Icon: Content, isSelected: false },
  { id: 8, title: "Uptight", Icon: Disappointed, isSelected: false },
  { id: 9, title: "Agitated", Icon: Emotional, isSelected: false },
  { id: 10, title: "Heavy", Icon: Excited, isSelected: false },
  { id: 11, title: "Free from problems", Icon: Flustered, isSelected: false },
  { id: 12, title: "Aggressive", Icon: Freefromproblem, isSelected: false },
  { id: 13, title: "Crying", Icon: Grief, isSelected: false },
  { id: 14, title: "Scattered", Icon: Happy, isSelected: false },
  { id: 15, title: "Lighter", Icon: Happy2, isSelected: false },
  { id: 16, title: "Confused", Icon: Heavy, isSelected: false },
  { id: 17, title: "Grief", Icon: Lighter, isSelected: false },
  { id: 18, title: "Excited", Icon: Negative, isSelected: false },
  { id: 19, title: "Flustered", Icon: Negativemood, isSelected: false },
  { id: 20, title: "Bad mood", Icon: Nothanks, isSelected: false },
  { id: 21, title: "Overwhelm", Icon: Overwhelm, isSelected: false },
  { id: 22, title: "Content", Icon: Sad, isSelected: false },
  { id: 23, title: "Not thinking", Icon: Scattered, isSelected: false },
  { id: 24, title: "Disappointed", Icon: Uptight, isSelected: false },
];
// empowerment
export const preEmojiList = [
  { id: 2, title: "Emotional", Icon: Emotional, isSelected: false },
  { id: 3, title: "Angry", Icon: Angry, isSelected: false },
  { id: 4, title: "Negative", Icon: Negative, isSelected: false },
  { id: 5, title: "Happy", Icon: Happy, isSelected: false },
  { id: 6, title: "Sad", Icon: Sad, isSelected: false },
  { id: 7, title: "Stressed", Icon: PreStressed, isSelected: false },
  { id: 8, title: "Peaceful", Icon: PrePeaceful, isSelected: false },
  { id: 9, title: "Confused", Icon: Confused, isSelected: false },
  { id: 10, title: "Tense", Icon: PreTense, isSelected: false },
  { id: 11, title: "Worried", Icon: Overwhelm, isSelected: false },
  { id: 12, title: "Positive", Icon: Excited, isSelected: false },
  { id: 13, title: "Free", Icon: PreFree, isSelected: false },
  {
    id: 14,
    title: "Calm",
    Icon: Freefromproblem,
    isSelected: false,
  },
  {
    id: 15,
    title: "Relaxed",
    Icon: PreRelaxed,
    isSelected: false,
  },
  {
    id: 16,
    title: "Light",
    Icon: PrePeaceful,
    isSelected: false,
  },
  { id: 17, title: "Neutral", Icon: PreNeutral, isSelected: false },
  { id: 18, title: "Joyful", Icon: PreJoyful, isSelected: false },
  { id: 89, title: "Aggressive", Icon: PreTense, isSelected: false },
];

export const preEmojiListUnstoppable = [
  { id: 2, title: "Emotional", Icon: Emotional, isSelected: false },
  { id: 3, title: "Angry", Icon: Angry, isSelected: false },
  { id: 4, title: "Neutral", Icon: Negative, isSelected: false },
  { id: 5, title: "Smiling", Icon: Happy, isSelected: false },
  { id: 6, title: "Unhappy", Icon: Sad, isSelected: false },
  { id: 7, title: "Exhausted", Icon: PreStressed, isSelected: false },
  { id: 8, title: "Happy", Icon: PrePeaceful, isSelected: false },
  { id: 9, title: "Confused", Icon: Confused, isSelected: false },
  { id: 10, title: "Negative", Icon: PreTense, isSelected: false },
  { id: 11, title: "Overwhelmed", Icon: Overwhelm, isSelected: false },
  { id: 12, title: "Excited", Icon: Excited, isSelected: false },
  { id: 13, title: "Positive", Icon: PreFree, isSelected: false },
  {
    id: 14,
    title: "Contented",
    Icon: Freefromproblem,
    isSelected: false,
  },
  {
    id: 15,
    title: "Relaxed",
    Icon: PreRelaxed,
    isSelected: false,
  },
  { id: 17, title: "Peaceful", Icon: PrePeaceful, isSelected: false },
  { id: 18, title: "Joy", Icon: PreNeutral, isSelected: false },
  { id: 19, title: "Sleepy", Icon: PreSleepy, isSelected: false },
  { id: 20, title: "Frustrated", Icon: PreTense, isSelected: false },
];
export const postEmojiList = [
  // empowerment
  { id: 1, title: "Emotional", Icon: EmotionalPost, isSelected: false },
  { id: 2, title: "Angry", Icon: AngryPost, isSelected: false },
  { id: 3, title: "Negative", Icon: NegativePost, isSelected: false },
  { id: 4, title: "Sad", Icon: SadPost, isSelected: false },
  { id: 5, title: "Happy", Icon: HappyPost, isSelected: false },
  { id: 6, title: "Aggressive", Icon: AggressivePost, isSelected: false },
  { id: 7, title: "Crying", Icon: CryingPost, isSelected: false },
  { id: 8, title: "Scattered", Icon: ScatteredPost, isSelected: false },
  { id:9, title: "Lighter", Icon: Light, isSelected: false },
  { id: 10, title: "Confused", Icon: ConfusedPost, isSelected: false },
  { id: 11, title: "Heavy", Icon: HeavyPost, isSelected: false },
  { id: 12, title: "Bad mood", Icon: BadMoodPost, isSelected: false },
  { id: 13, title: "Negative mood", Icon: NegativeMoodPost, isSelected: false },
  { id: 14, title: "Free from problems", Icon: Clam, isSelected: false },
  { id: 15, title: "overwhelm", Icon: OverwhelmPost, isSelected: false },
  { id: 16, title: "Grief", Icon: GriefPost, isSelected: false },
  { id: 17, title: "Content", Icon: ContentPost, isSelected: false },
  { id: 18, title: "Excited", Icon: Blushing, isSelected: false },
  { id: 19, title: "Uptight", Icon: Worried, isSelected: false },
  { id: 20, title: "Not thinking", Icon: NotThinkingPost, isSelected: false },
  { id: 21, title: "Agitated", Icon: AgitatedPost, isSelected: false },
  { id: 22, title: "Flustered", Icon: EmotionalPost, isSelected: false },
  { id: 23, title: "Disappointed", Icon: DispointedPost, isSelected: false },
];

export const postEmojiListUnstoppable = [
  { id: 1, title: "Tense", Icon: Tense, isSelected: false },
  { id: 2, title: "Feeling Flat", Icon: FlatFeeling, isSelected: false },
  { id: 3, title: "Smiling", Icon: Smiling, isSelected: false },
  { id: 4, title: "Confused", Icon: ConfusedPost1, isSelected: false },
  { id: 5, title: "Unhappy", Icon: Unhappy3, isSelected: false },
  { id: 6, title: "Frustrated", Icon: HeavyPost, isSelected: false },
  { id: 7, title: "Happy", Icon: Positive, isSelected: false },
  { id: 8, title: "Negative", Icon: Negative1, isSelected: false },
  { id: 9, title: "Annoyed", Icon: Annoyed1, isSelected: false },
  { id: 10, title: "Sleepy", Icon: PreSleepy, isSelected: false },
  { id: 11, title: "Joy", Icon: joyPost, isSelected: false },
  { id: 12, title: "Emotional", Icon: emotional1Post, isSelected: false },
  { id: 13, title: "Peaceful", Icon: Peaceful3, isSelected: false },
  { id: 14, title: "Angry", Icon: Angry1, isSelected: false },
  { id: 15, title: "Scared", Icon: Scared, isSelected: false },
  { id: 16, title: "enthusiastic", Icon: Excited, isSelected: false },
  { id: 17, title: "Excited", Icon: Excited1, isSelected: false },
  { id: 18, title: "Crying", Icon: Crying1, isSelected: false },
  { id: 19, title: "Aggressive", Icon: aggesive1, isSelected: false },
];

const ShareFeeling = ({ preEmoji, handleEmojis, isEmopwerment = false }) => {
  const [emojiList, setEmojiList] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(bgImage);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  useEffect(() => {
    let _bg;
    if (isEmopwerment) {
      _bg = empowermentBg;
    } else {
      _bg = unstoppableBg;
    }
    setBackgroundImage(_bg);
  }, []);

  useEffect(() => {
    let _emojis;
    if (preEmoji && isEmopwerment) {
      _emojis = [...preEmojiList];
    } else if (preEmoji && !isEmopwerment) {
      _emojis = [...postEmojiListUnstoppable];
    } else if (!preEmoji && isEmopwerment) {
      _emojis = [...postEmojiList];
    } else if (!preEmoji && !isEmopwerment) {
      _emojis = [...postEmojiListUnstoppable];
    }

    _emojis = _emojis.map((emoji) => {
      return { ...emoji, isSelected: false };
    });
    setEmojiList([..._emojis]);
  }, [preEmoji]);

  const handleSelectedEmoji = (id) => {
    let _emojiList = [...emojiList];
    let _emoji = _emojiList.find((emoji) => emoji.id == id);
    _emoji.isSelected = !_emoji.isSelected;
    setEmojiList([..._emojiList]);
  };

  const handleNextButton = () => {
    let _emojis = emojiList.filter((emoji) => emoji.isSelected === true);
    _emojis.length
      ? handleEmojis(preEmoji, _emojis)
      : handleToastMessage("error", "Please select the Emoji!");
    _emojis.length && setIsPopupOpen(false);
  };

  return (
    <Popup
      isOpen={isPopupOpen}
      isFullScreen={true}
      width="100%"
      childClassName={"p-0"}
      borderRadius={0}
      isShowHeader={false}
      containerClassName={"m-0"}
    >
      <div
        className={`container-fluid ${Style.mainContainer}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={`${Style.titleBar}`}>
          <div className="d-flex justify-content-between">
            <h2 className={`text-center w-50 ${Style.title}`}>
              {" "}
              {preEmoji
                ? `Please share how you are currently feeling now`
                : `Please share how you are feeling after your session`}
            </h2>
            <div className={`align-self-end ${Style.whiteButtonContainer}`}>
              <button
                onClick={handleNextButton}
                class={`white-btn d-flex justify-content-center align-items-center
                        `}
              >
                Next
                <span>
                  <RightArrow height={30} width={30} />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className={`container mt-5 pb-5 ${Style.emojiContainer}`}>
          <div
            className={`d-flex justify-content-center mb-3 ${Style.filterEmojies}`}
          >
            {emojiList
              .filter((emoji) => emoji.isSelected)
              .map((item) => (
                <img src={item.Icon} height={130} width={130} />
              ))}
          </div>
          <div className={`d-flex justify-content-center flex-wrap `}>
            {emojiList.map((item) => (
              <div key={item.id} className={`mt-3 ${Style.emojiBox}`}>
                <EmojiBox
                  title={item.title}
                  Icon={item.Icon}
                  id={item.id}
                  isSelected={item.isSelected}
                  handleSelectedEmoji={handleSelectedEmoji}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Popup>
  );
};
export default ShareFeeling;
