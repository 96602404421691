import React, { useState } from 'react'
import Style from './introductoryScreen.module.scss'
import boostBackground from "../../../assets/images/dashboardModule/empowerment/bg.png";
import ClockIcon from "../../../assets/images/clockIcon.svg";
import rightArrow from "../../../assets/images/rightArrow.svg";
import { Box, Button } from '@mui/material';
import { ReactComponent as PlayButtonIcon } from "../../../assets/images/play-button.svg";
import ReactPlayer from 'react-player';

const IntroductoryScreen = ({
    step,
    title = 'title',
    Icon = ClockIcon,
    description = 'description',
    backgroundImage = boostBackground,
    buttonText = 'Next',
    isVideo,
    videoId,
    thumbnail,
    handleClick
}) => {
    const [isPlayYoutubeVideo, setIsPlayYoutubeVideo] = useState(false);

    const handleButtonClick = (step) => {
        handleClick(step)
    }
    return (

        <div
            className={`container-fluid ${Style.mainContainer}`}
            style={{
                backgroundImage: `url('${backgroundImage}')`,
            }}
        >
            <div className="container">
                <span className={`d-flex justify-content-center ${Style.welcome}`}>
                    {title}
                </span>
                <div className="row mt-4 pt-3">
                    <div className={`col-lg-5 col-md-12 col-sm-12 mb-2  ${Style.leftMainContainer}`}>
                        <div className={`${Style.leftContainer}`} style={{width: isVideo ? 'unset':'300px'}}>
                            <Box className={`d-flex flex-column align-items-center`}>

                                {
                                    isVideo &&
                                    <>
                                        {!isPlayYoutubeVideo ? (
                                            <div className={Style.thumnailContainer}>
                                                <div
                                                    className={`${Style.playbutton} cursor-pointer`}
                                                    onClick={() => setIsPlayYoutubeVideo(true)}
                                                >
                                                    <PlayButtonIcon height={45} />
                                                </div>
                                                <img src={thumbnail} draggable="false" />
                                            </div>
                                        ) : (
                                            <ReactPlayer
                                                className={"video-container"}
                                                height={"100%"}
                                                width={"100%"}
                                                playing={true}
                                                controls={true}
                                                url={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                                            />
                                        )}

                                    </>
                                }
                                {
                                    !isVideo &&
                                    <div className={`${Style.imageContainer}`}>
                                        <img src={Icon} />
                                    </div>
                                }
                            </Box>
                        </div>
                    </div>
                    <div
                        className={`col-lg-7  col-md-12 col-sm-12 mb-2 ${Style.rightPortion}`}
                    >
                        <div className={` ${Style.descriptionContainer}`}>
                            <span className={`${Style.description}`} dangerouslySetInnerHTML={{ __html: description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" }}>
                                {/* {description} */}
                            </span>
                        </div>
                    </div>
                </div>

            </div>

            <div className={`d-flex justify-content-end  ${Style.buttonContainer}`}>
                <Button
                    onClick={() => handleButtonClick(step)}
                    className={`mt-4 white-btn  text-transform-none ${Style.submitButton}`}
                >
                    {buttonText}
                    <span className="ms-2">
                        <img src={rightArrow} width="30px" />
                    </span>
                </Button>
            </div>
        </div >
    )
}

export default IntroductoryScreen