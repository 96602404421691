let init = {
  activePlaylistArray: [],
  activePlayerIndex: 0
}

let playlistInitialState = {
  playlist: [],
  currentTrackIndex: -1
};

let playerInstance = null;

export const musicList = (state = init, action) => {
  switch (action.type) {
    case 'ADD_MUSICLIST':
      return {
        activePlayerIndex: action.music.activePlayerIndex,
        activePlaylistArray: action.music.musicList
      }

    case 'PLAY_MUSIC':
      let _isPlay = action.music.musicList
      _isPlay[action.music.index].isPlay = true

      return {
        activePlaylistArray: [..._isPlay],
        activePlayerIndex: action.music.activePlayerIndex,
      }

    case 'PAUSE_MUSIC':
      let _isPause = action.music.musicList
      _isPause[action.music.index].isPlay = false

      return {
        activePlaylistArray: [..._isPause],
        activePlayerIndex: action.music.activePlayerIndex,
      }
    default:
      return state
  }
}

export const playlist = (state = playlistInitialState, action) => {
  switch (action.type) {
    case "SET_PLAYLIST":
      return {
        playlist: action.playIntent.playlist,
        currentTrackIndex: action.playIntent.trackIndex
      }
    case "SET_CURRENT_TRACK_IN_PLAYLIST":
      return {
        ...state,
        currentTrackIndex: action.playIntent.trackIndex
      }
    default:
      return state;
  }
}

export const audioPlayer = (state = playerInstance, action) => {
  switch (action.type) {
    case 'MAYBE_SET_PLAYER_INSTANCE':
      return action.instance;
    default:
      return state;
  }
}

export const playerVisibility = (state = false, action) => {
  switch (action.type) {
    case 'SET_PLAYER_VISIBILITY':
      return action.visibility;
    default:
      return state;
  }
}

export const PreviousMusicList = (state = [{}], action) => {
  switch (action.type) {
    case 'ADD_PREVIOUS_MUSICLIST':
      return action.music
    default:
      return state
  }
}
export const inActivePlayerIndex = (state = 0, action) => {
  switch (action.type) {

    case 'INACTIVE_PLAYER_INDEX':
      return action.index
    default:
      return state
  }
}
export const musicIndex = (state = 0, action) => {
  switch (action.type) {
    case 'SELECTED_MUSIC_INDEX':
      return action.index
    default:
      return state
  }
}
export const previousMusicIndex = (state = 0, action) => {
  switch (action.type) {
    case 'PREVIOUS_MUSIC_INDEX':
      return action.index
    default:
      return state
  }
}
export const currentTime = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CURRENT_TIME':
      return action.time
    default:
      return state
  }
}
export const yourPlaylistCount = (state = 0, action) => {
  switch (action.type) {
    case 'INC_DEC_YOUR_PLAYLISt':
      return action.count
    default:
      return state
  }
}
export const audiosCount = (state = false, action) => {
  switch (action.type) {
    case 'IS_COUNT_CHANAGE':
      return action.isCount
    default:
      return state
  }
}