import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import IntroductoryScreen from '../../../shared/components/introductoryScreen/introductoryScreen';
import boostBackground from "../../../assets/images/dashboardModule/boost/boostWelcome.png";
import headphone from "../../../assets/images/dashboardModule/boost/headphone.svg";
import bar from "../../../assets/images/dashboardModule/boost/bar.svg";
import change from "../../../assets/images/dashboardModule/boost/change.svg";
import sleepymoon from "../../../assets/images/dashboardModule/boost/sleepymoon.svg";
import sun from "../../../assets/images/dashboardModule/boost/sun.svg";
import play from "../../../assets/images/dashboardModule/boost/play.svg";

import { getOnboardingVideosAPICall, setBoostCompleteOnbordingAPICall } from '../homeService/homeService';
import { caseStudyTypeEnum } from '../../../shared/js/enums';

const BoostWelcome = () => {
    const navigate = useNavigate();
    const [welcomeStep, setWelcomeStep] = useState([{
        step: 1,
        title: 'Making the most of Boost audio',
        description: 'To maximise your 14-day Boost free trial, explore all the fantastic, life-changing brain retraining audios in our library.',
        Icon: headphone,
        buttonText: 'Next'
    }]);
    const [onboardingContent, setOnboardingContent] = useState([]);

    useEffect(() => {
        getOnboardingVideos();
    }, []);

    const getOnboardingVideos = async () => {
        let _response = await getOnboardingVideosAPICall('', caseStudyTypeEnum.explainerVideo);
        if (_response.isSuccess) {
            setOnboardingContent(_response.videoList);
        }
    }

    const handleClick = async (step) => {
        let _welcomeStep = {}
        switch (step) {
            case 1:
                _welcomeStep = {
                    step: 2,
                    title: '',
                    description: 'Create a playlist once you have identified the audios that resonate or inspire you.<br/> <br/> I suggest adding between 18 to 20 audios per playlist. If you are unsure of creating a playlist, select one of my specially designed bundles from my suggested playlist library.<br/> <br/> Once you make your playlist, add your favourite audios to it.',
                    Icon: bar,
                    buttonText: 'Next'
                }
                break;
            case 2:
                _welcomeStep = {
                    step: 3,
                    title: '',
                    description: 'There are so many life-changing audios to choose from that I encourage you to create multiple playlists and alternate between them depending on how you feel.',
                    Icon: change,
                    buttonText: 'Next'
                }
                break;
            case 3:
                _welcomeStep = {
                    step: 4,
                    title: '',
                    description: 'Use while you sleep at night on a whisper quiet volume so you barely hear the sound. The audios are designed to be listened to below a threshold volume. This ensures you or your partner are not disturbed and you get a restful night’s sleep.',
                    Icon: sleepymoon,

                    buttonText: "Next"
                }
                break;
            case 4:
                _welcomeStep = {
                    step: 5,
                    title: '',
                    description: 'You can use it during the day as a pick-me-up tonic to release the stress and tension of a busy day. Instead of meditating, use one or two audios that resonate with you. Wrap yourself up in bed, drift off to sleep with your eyes closed while my audios do all the work for you.',
                    Icon: sun,
                    buttonText: "Next"
                }
                break;
            case 5:
                _welcomeStep = {
                    step: 6,
                    title: '',
                    description: 'To better understand how your Boost library works, I recommend that you watch my 2-minute explanation video, so you get maximum benefits from your membership. <br/> <br/>Remember, you have access to free unlimited chat support, so please reach out if you are unsure of anything to do with using your app.',
                    Icon: play,
                    isVideoPlay: true,
                    videoId: onboardingContent[0].videoUrl,
                    thumbnail: onboardingContent[0].thumbnail,
                    buttonText: "Let's Start"
                }
                break;
            case 6:
                await setBoostCompleteOnbordingAPICall(1);
                navigate("/dashboard/boost");
                break;
        }
        setWelcomeStep([_welcomeStep])
    }
    return (
        <div>
            {
                welcomeStep.map((data) =>
                    <IntroductoryScreen
                        step={data.step}
                        title={data.title}
                        Icon={data.Icon}
                        isVideo={data?.isVideoPlay || false}
                        videoId={data?.videoId || ""}
                        thumbnail={data?.thumbnail || ""}
                        description={data.description}
                        backgroundImage={boostBackground}
                        buttonText={data.buttonText}
                        handleClick={handleClick}
                    />
                )
            }
        </div>
    )
}

export default BoostWelcome