import betterEnv from "./env.json"

const environment = {
  baseUrl: betterEnv.baseUrl,
  serverUrl: betterEnv.serverUrl,

  appUrl: window.location.origin,
  
  // TODO: Marked for removal. Pretty dangerous, and seems to be not in use.
  s3Token: betterEnv.s3,

  googleClientId: betterEnv.googleClientId,
  facebookAppId: betterEnv.facebookAppId,

  stripePublicKey: betterEnv.stripe,

  // This is a JWT token issued to guests via web app. Dangerous, and should probably be considered for refactoring
  guestToken: betterEnv.guestToken
};

export default environment;