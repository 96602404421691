import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../shared/components/customButton/customButton";
import Style from "./sessionDetail.module.scss";

const SessionDetail = ({
  handleSessionstart,
  sessionDetail,
  title,
  month,
  isShowTimer,
}) => {
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { page } = state || {};
  const { sessionId } = state || {};
  useEffect(() => {
    if (sessionDetail?.description) {
      let _description = sessionDetail.description.replace(
        new RegExp("\n", "g"),
        "<br />"
      );
      setDescription(_description);
    }
  }, [sessionDetail?.description]);
  useEffect(()=>{
    if(page === 'empowerment')
    {
      handleSessionstart(true, sessionId)
    }
    else if(page === 'unstoppable')
    {
      handleSessionstart(true, sessionId)
    }
  },[])
  const handleStartSession = () => {
    handleSessionstart(true, sessionDetail?.id);
  };
  const handleWelcomeScreen = () => {
    if(sessionDetail?.id === 1)
    {
      navigate("/empowermetUnstopableWelcome",{
        state: {
            page: 'empowerment',
            sessionId:sessionDetail?.id
        },
      })
    }else if(sessionDetail?.id === 16){
      navigate("/empowermetUnstopableWelcome",{
        state: {
            page: 'unstoppable',
            sessionId:sessionDetail?.id
        },
      })
    }
  }
  return (
    <>

      <div
        className={`d-flex align-items-center text-center justify-content-between mb-5 ${Style.container} `}
      >
        <div className={`text-center `}>
          <h6 className={`mb-4`}>
            About {title} {month ? `Month ${month}` : ""}
          </h6>
          <p className="text-justify" dangerouslySetInnerHTML={{ __html: description }}>{}</p>
          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
        </div>
        <div className="mt-5 d-flex flex-column align-items-center">
          {(sessionDetail?.id === 1 || sessionDetail?.id === 16) && <p className="text-decoration-underline mb-0" role="button" onClick={handleWelcomeScreen}>Learn More</p>}
          <CustomButton
            disabled={isShowTimer}
            title={"Start Your Session"}
            handleButtonClick={handleStartSession}
            buttonStyle={`white-btn cursor-pointer`}
          />
        </div>
      </div>
    </>
  );
};
export default SessionDetail;
