import React from 'react'
import CaseStudiesCard from '../../../home/caseStudies/caseStudiesCard/caseStudiesCard'
import  Style  from './videoContentCard.module.scss'

const VideoContentCard = ({slide, thumbnail, isShowTitle, isThumbnail,handleVideoControl,index}) => {
  return (
    <div key={Math.random()} className={`${Style.carouselSlideContainer}`}>
        <div className={`${Style.carouselSlide}`}>
          <CaseStudiesCard
            title={slide.title}
            videoUrl={slide?.videoUrl}
            isPlaying={slide?.isPlaying || false}
            videoThumbnail={thumbnail}
            isShowTitle={isShowTitle}
            isThumbnail={isThumbnail}
            index={index}
            handleVideoControl={handleVideoControl}
            allowFullScreen
          />
        </div>
      </div>
  )
}

export default VideoContentCard