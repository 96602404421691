export class PurchaseProgramModel {
  constructor() {
    this.id = null;
    this.title = "";
    this.description = "";
    this.isRecomended = false;
    this.navigateLink ="";
    this.introVideo ="";
    this.imageUrl = "";
    this.authorDesignation = "";
    this.authorName="";
  }
}
