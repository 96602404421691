import { combineReducers } from 'redux'
import {loginUserInfo, isProfileUpdate} from './Login/index'
import {unSeenNotificationCount} from './notification/index'
import {happinessGraphStatus} from './graph/index'
import {
    musicList,
    audioPlayer,
    playerVisibility,
    playlist,
    musicIndex,
    inActivePlayerIndex,
    PreviousMusicList,
    previousMusicIndex,
    currentTime,
    yourPlaylistCount,
    audiosCount
} from './wavesurfer/index'

const rootReducer = combineReducers({
    loginUserInfo,
    musicList,
    audioPlayer,
    playerVisibility,
    playlist,
    musicIndex,
    inActivePlayerIndex,
    PreviousMusicList,
    previousMusicIndex,
    currentTime,
    yourPlaylistCount,
    audiosCount,
    isProfileUpdate,
    unSeenNotificationCount,
    happinessGraphStatus
})
export default rootReducer;