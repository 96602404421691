export class UserModel {
  constructor() {
    this.userId = "";
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.imageUrl = "";
    this.postalCode = "";
    this.dob = "";
    this.mobileNumber = "";
    this.status = "";
    this.onBordingStatus = "";
    this.onEmpowermentStatus = "";
    this.onUnstoppableStatus = "";
    this.isBannerSeen = 0;
    this.scores = null;
    this.couponId = null;
  }
}
